import Image from "next/image";
import Link from "next/link";
import * as React from "react";
import { format } from "react-string-format";
import { getSiteImageFullPath } from "~/lib/siteMedia";
import messages from "~/resources/messages/en-us/components/layout/footer.json";

interface IFooterProps {}

const Footer: React.FC<IFooterProps> = () => {
	return (
		<div className="w-full text-white bg-epic-footer-orange" id="footer">
			<div className="flex flex-col justify-between max-w-screen-xl py-8 mx-auto gap-y-12 md:flex-row">
				<div className="flex flex-row flex-wrap ml-8 text-sm md:mr-8 gap-y-12">
					<div>
						<div className="font-extrabold font-title">
							{messages.footer.aboutLinksHeading.value}
						</div>
						<div className="w-[146px] 2xs:w-[300px] 2xs:columns-2 columns-1 break-inside-avoid-column gap-x-4 font-title">
							<Link
								className="block hover:underline"
								href="/about"
								id="footer_about"
								title={messages.footer.aboutUsLinkTooltip.value}
							>
								{messages.footer.aboutUsLinkCaption.value}
							</Link>
							<a
								className="block hover:underline"
								href="https://careers.epic.com"
								id={`footer_careers`}
								title={messages.footer.careersLinkTooltip.value}
							>
								{messages.footer.careersLinkCaption.value}
							</a>

							<Link
								className="block hover:underline"
								href="/contact"
								id="footer_contact"
								title={messages.footer.contactUsLinkTooltip.value}
							>
								{messages.footer.contactUsLinkCaption.value}
							</Link>

							<a
								className="block hover:underline"
								href="https://link.epic.com"
								id={`footer_link`}
								title={messages.footer.epicLinkLinkTooltip.value}
							>
								{messages.footer.epicLinkLinkCaption.value}
							</a>

							<Link
								className="block hover:underline"
								href="/giving"
								id="footer_giving"
								title={messages.footer.givingLinkTooltip.value}
							>
								{messages.footer.givingLinkCaption.value}
							</Link>

							<Link
								className="block hover:underline"
								href="/legal-disclosures"
								id="footer_legaldisclosures"
								title={messages.footer.legalLinkTooltip.value}
							>
								{messages.footer.legalLinkCaption.value}
							</Link>
							<Link
								className="block hover:underline"
								href="/privacypolicies"
								id="footer_about_privacypolicies"
								title={messages.footer.privacyLinkTooltip.value}
							>
								{messages.footer.privacyLinkCaption.value}
							</Link>
							<a
								className="block hover:underline"
								href="https://training.epic.com"
								id={`footer_training`}
								title={messages.footer.trainingLinkTooltip.value}
							>
								{messages.footer.trainingLinkCaption.value}
							</a>
							<a
								className="block hover:underline"
								href="https://efficiency.epic.com"
								id={`footer_efficiency.epic.com`}
								title={messages.footer.efficiencyLinkTooltip.value}
							>
								{messages.footer.efficiencyLinkCaption.value}
							</a>
							<Link
								className="block hover:underline"
								href="/visiting"
								id="footer_visiting"
								title={messages.footer.visitingLinkTooltip.value}
							>
								{messages.footer.visitingLinkCaption.value}
							</Link>
						</div>
					</div>
					<div>
						<div className="font-extrabold font-title">
							{messages.footer.eventLinksHeading.value}
						</div>
						<div className="w-[146px] columns-1 gap-x-4 font-title">
							<a
								className="block hover:underline"
								href="https://www.ugm.epic.com"
								id={`footer_ugm`}
								title={messages.footer.ugmLinkTooltip.value}
							>
								{messages.footer.ugmLinkCaption.value}
							</a>
							<a
								className="block hover:underline"
								href="https://xgm.epic.com"
								id={`footer_xgm`}
								title={messages.footer.xgmLinkTooltip.value}
							>
								{messages.footer.xgmLinkCaption.value}
							</a>
							<a
								className="block hover:underline"
								href="https://www.egm.epic.com"
								id={`footer_egm`}
								title={messages.footer.egmLinkTooltip.value}
							>
								{messages.footer.egmLinkCaption.value}
							</a>
							<a
								className="block hover:underline"
								href="https://www.apgm.epic.com/"
								id={`footer_agm`}
								title={messages.footer.apgmLinkTooltip.value}
							>
								{messages.footer.apgmLinkCaption.value}
							</a>
							<a
								className="block hover:underline"
								href="https://www.connectcollabepic.com"
								id={`footer_connectcollabepic`}
								title={messages.footer.connectCollaborationLinkTooltip.value}
							>
								{messages.footer.connectCollaborationLinkCaption.value}
							</a>
						</div>
					</div>
				</div>

				<div className="flex flex-col gap-5 pr-1 text-xs md:pr-6 pl-6 md:pl-0 font-title md:basis-[180px] md:shrink md:grow-0 lg:basis-auto lg:grow">
					<div className="flex items-center justify-start gap-10 md:justify-end">
						<a
							href="https://www.facebook.com/LifeAtEpic"
							id={`footer_facebook`}
							title={messages.footer.facebookLinkTooltip.value}
							className="relative w-6 h-6"
						>
							<Image
								alt={messages.footer.facebookAltText.value}
								src={getSiteImageFullPath("social-media/facebook.svg")}
								className="object-contain"
								fill
							/>
						</a>
						<a
							href="https://www.linkedin.com/company/epic1979"
							id={`footer_linkedin`}
							title={messages.footer.linkedinLinkTooltip.value}
							className="relative w-6 h-6"
						>
							<Image
								alt={messages.footer.linkedinAltText.value}
								src={getSiteImageFullPath("social-media/linked-in.svg")}
								className="object-contain"
								fill
							/>
						</a>
						<a
							href="https://www.youtube.com/c/EpicEHR"
							id={`footer_youtube`}
							title={messages.footer.youtubeLinkTooltip.value}
							className="relative w-6 h-6"
						>
							<Image
								alt={messages.footer.youtubeAltText.value}
								src={getSiteImageFullPath("social-media/you-tube.svg")}
								className="object-contain"
								fill
							/>
						</a>
					</div>
					<div className="pl-2 font-medium text-left font-title md:text-right md:pl-0">
						{format(messages.footer.copyright.value, new Date().getFullYear())}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Footer;
